<template>
  <div class="answer">
    <div class="width_1200">
      <div class="bread">
        <bread-crumb></bread-crumb>
      </div>
    </div>
    <div class="width_1200">
      <div class="cont">
        <div class="top" v-if="mobile != true">
          <div class="title" @click="quitPractice()">
            <i class="el-icon-arrow-left"></i>
            退出练习
          </div>
        </div>
        <el-row :gutter="20">
          <el-col :span="18">
            <div class="list" v-if="list.length > 0">
              <div class="li" v-for="(li, i) in list" :key="i">
                <div class="paperBank" v-if="number == i + 1">
                  <div class="questionTitle">
                    <p class="tmtype">
                      <span :class="li.QuestionCategory == 0
                            ? 'bgc_3A78F9'
                            : li.QuestionCategory == 1
                            ? 'bgc_FBB03B'
                            : li.QuestionCategory == 2
                            ? 'bgc_00AE62'
                            : li.QuestionCategory == 3
                            ? 'bgc_B938B5'
                            : li.QuestionCategory == 4
                            ? 'bgc_9682EC'
                            : 'bgc_37E6CF'">
                        {{
                          li.QuestionCategory == 0
                            ? "单选题"
                            : li.QuestionCategory == 1
                            ? "多选题"
                            : li.QuestionCategory == 2
                            ? "判断题"
                            : li.QuestionCategory == 3
                            ? "填空题"
                            : li.QuestionCategory == 4
                            ? "简答题"
                            : "论述题"
                        }}
                      </span>
                      <span>题目{{ i + 1 }}：{{ li.QuestionTitle }}</span>
                    </p>
                    <div class="correction" @click="correction(li)">
                      纠错 <i class="el-icon-edit-outline"></i>
                    </div>
                    <!-- <p class="tmmc">{{lb.QuestionTitle}}</p> -->
                  </div>
                  <el-radio-group
                    v-if="li.QuestionCategory === 0"
                    v-model="li.checkList"
                    @change="handChange(li)"
                  >
                    <p v-for="(lbb, p) in li.BankItem" :key="p">
                      <el-radio :label="lbb.ItemNum"
                        >{{ lbb.ItemNum }}、{{ lbb.ItemTitle }}</el-radio
                      >
                    </p>
                    <!-- <el-button
                      size="mini"
                      v-if="li.parsingShow == false"
                      @click="parsClick(li)"
                      >解析</el-button
                    > -->
                    <div v-if="li.parsingShow == true" class="js">
                      <div>
                        【答案】: {{li.AnswerStr == null ? '无' : li.AnswerStr}}
                      </div>
                      <div>
                        【解析】:
                        <span v-html="li.QuestionAnalysis == null ? '无' : li.QuestionAnalysis"></span>
                      </div>
                      <div>
                        【点拨】: {{li.Dial == null ? '无' : li.Dial}}
                      </div>
                    </div>
                  </el-radio-group>
                  <el-checkbox-group
                    v-else-if="li.QuestionCategory === 1"
                    v-model="li.checkList"
                    @change="handChange(li)"
                  >
                    <p v-for="(lbb, p) in li.BankItem" :key="p">
                      <el-checkbox :label="lbb.ItemNum"
                        >{{ lbb.ItemNum }}、{{ lbb.ItemTitle }}</el-checkbox
                      >
                    </p>
                    <!-- <el-button
                      size="mini"
                      v-if="li.parsingShow == false"
                      @click="parsClick(li)"
                      >解析</el-button
                    > -->
                    <div v-if="li.parsingShow == true" class="js">
                      <div>
                        【答案】: {{li.AnswerStr == null ? '无' : li.AnswerStr}}
                      </div>
                      <div>
                        【解析】:
                        <span v-html="li.QuestionAnalysis == null ? '无' : li.QuestionAnalysis"></span>
                      </div>
                      <div>
                        【点拨】: {{li.Dial == null ? '无' : li.Dial}}
                      </div>
                    </div>
                  </el-checkbox-group>
                  <el-radio-group
                    v-else-if="li.QuestionCategory === 2"
                    v-model="li.checkList"
                    @change="handChange(li)"
                  >
                    <p v-for="(lbb, p) in li.BankItem" :key="p">
                      <el-radio :label="lbb.ItemNum"
                        >{{ lbb.ItemNum }}、{{ lbb.ItemTitle }}</el-radio
                      >
                    </p>
                    <!-- <el-button
                      size="mini"
                      v-if="li.parsingShow == false"
                      @click="parsClick(li)"
                      >解析</el-button
                    > -->
                    <div v-if="li.parsingShow == true" class="js">
                      <div>
                        【答案】: {{li.AnswerStr == null ? '无' : li.AnswerStr}}
                      </div>
                      <div>
                        【解析】:
                        <span v-html="li.QuestionAnalysis == null ? '无' : li.QuestionAnalysis"></span>
                      </div>
                      <div>
                        【点拨】: {{li.Dial == null ? '无' : li.Dial}}
                      </div>
                    </div>
                  </el-radio-group>
                  <div v-else-if="li.QuestionCategory === 3">
                    <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入填空,多个填空以中文；隔开"
                      @change="handChange(li)"
                      v-model="li.checkList"
                    ></el-input>
                    <!-- <el-button
                      size="mini"
                      v-if="li.parsingShow == false"
                      @click="parsClick(li)"
                      >解析</el-button
                    > -->
                    <div v-if="li.parsingShow == true" class="js">
                      <div>
                        【答案】: {{li.AnswerStr == null ? '无' : li.AnswerStr}}
                      </div>
                      <div>
                        【解析】:
                        <span v-html="li.QuestionAnalysis == null ? '无' : li.QuestionAnalysis"></span>
                      </div>
                      <div>
                        【点拨】: {{li.Dial == null ? '无' : li.Dial}}
                      </div>
                    </div>
                  </div>
                  <div v-else-if="li.QuestionCategory === 4">
                    <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入简答"
                      @change="handChange(li)"
                      v-model="li.checkList"
                    ></el-input>
                    <!-- <el-button
                      size="mini"
                      v-if="li.parsingShow == false"
                      @click="parsClick(li)"
                      >解析</el-button
                    > -->
                    <div v-if="li.parsingShow == true" class="js">
                      <div>
                        【答案】: {{li.AnswerStr == null ? '无' : li.AnswerStr}}
                      </div>
                      <div>
                        【解析】:
                        <span v-html="li.QuestionAnalysis == null ? '无' : li.QuestionAnalysis"></span>
                      </div>
                      <div>
                        【点拨】: {{li.Dial == null ? '无' : li.Dial}}
                      </div>
                    </div>
                  </div>
                  <div v-else-if="li.QuestionCategory === 5">
                    <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入论述"
                      @change="handChange(li)"
                      v-model="li.checkList"
                    ></el-input>
                    <!-- <el-button
                      size="mini"
                      v-if="li.parsingShow == false"
                      @click="parsClick(li)"
                      >解析</el-button
                    > -->
                    <div v-if="li.parsingShow == true" class="js">
                      <div>
                        【答案】: {{li.AnswerStr == null ? '无' : li.AnswerStr}}
                      </div>
                      <div>
                        【解析】:
                        <span v-html="li.QuestionAnalysis == null ? '无' : li.QuestionAnalysis"></span>
                      </div>
                      <div>
                        【点拨】: {{li.Dial == null ? '无' : li.Dial}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="list" v-else>
              <div class="li">
                <div class="paperBank">
                  <no-data></no-data>
                </div>
              </div>
            </div>
            <div>
               <el-row :gutter="20" v-if="list.length > 0">
                <el-col :span="24">
                  <div class="qhtm">
                    <el-row>
                      <el-col :span="8" @click.native="lastQuestion()">
                        <i class="el-icon-arrow-left"></i>
                        上一题
                      </el-col>
                      <el-col :span="8" @click.native="parsClick()">
                        <img src="../../assets/images/lamp.svg" width="16" />
                        查看答案
                      </el-col>
                      <el-col :span="8" @click.native="nextQuestion()">
                        下一题
                        <i class="el-icon-arrow-right"></i>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
                <el-col :span="6"></el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="list">
              <div v-if="list.length > 0">
                <ul class="li">
                  <li
                    @click="onClick(i + 1)"
                    v-for="(li, i) in list"
                    :key="i"
                    :class="(li.checkList != '' ? 'bgc_active' : '') ||
                ( number == i+1 ? 'bgc_active1' : '')"
                  >
                    {{ i + 1 }}
                  </li>
                </ul>
                <div class="xs">
                  <div><i></i>已做</div>
                  <div><i></i>未做</div>
                  <div><i></i>选中</div>
                </div>
                <div class="text_align_center">
                  <el-button class="buy" @click="buy()">立即提交</el-button>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
       
      </div>
    </div>
    <!--纠错弹窗-->
    <dialog-Visible
      v-if="dialogVisible != false"
      type="1"
      :width="width"
      :dialogVisible="dialogVisible"
      :obj="correctionArr"
      @find="getFind"
      @close="getClose()"
    ></dialog-Visible>
  </div>
</template>

<script>
import BreadCrumb from "@/components/breadcrumb.vue";
import dialogVisible from "@/components/components/dialogVisible.vue";
import {
  questionBankQueryChapterQuestion,
  questionBankSavePaperRecord,
  questionBankCorrectionQuestion
} from "@/api/questionBank";
import noData from "@/components/noData";
import common from '@/utils/common.js';
export default {
  data() {
    return {
      answer: {},
      radio: "",
      list: [],
      obj: [],
      number: 1,
      mobile:false,
      dialogVisible: false,
      width: "",
      correctionArr:{},
      useTime:""
    };
  },
  components: {
    BreadCrumb,dialogVisible,noData
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("userInfo"));
     let sUserAgent = navigator.userAgent.toLowerCase();
      if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
      //跳转移动端页面
        this.mobile = true;
      } else {
      //跳转pc端页面
        this.mobile = false;
      }
    this.init();
    // this.start(true);
    common.timeCounting(true);
  },
  mounted() {},
  methods: {
    async getFind(e){
      console.log(e);
      const res = await questionBankCorrectionQuestion(e);
      if (res.success === true) {
        this.$message.success("提交成功");
        this.dialogVisible = false;
      } else {
        this.$message.error(res.msg);
      }
    },
    // 弹窗关闭
    getClose() {
      this.dialogVisible = false;
    },
    //纠错
    correction(data) {
      this.correctionArr = {Id:data.QuestionId,Name:data.QuestionTitle}
      this.dialogVisible = true;
      if (this.mobile == true) {
        this.width = "85%";
      } else {
        this.width = "400px";
      }
    },
    //解析
    parsClick() {
      this.list[this.number-1].parsingShow = true;
    },
    onClick(i) {
      this.number = i;
    },
    // 上一题
    lastQuestion() {
      if (this.number < 1) {
        this.number = 1;
        return false;
      }
      this.number--;
    },
    // 下一题
    nextQuestion() {
      // console.log(this.list[this.number].checkList)
      // console.log(this.list[this.number].checkList)
      if (this.number >= this.list.length) {
        this.number = this.list.length;
        this.$message.info("最后一题");
        return false;
      }
      let i = this.number - 1;
      if (this.list[i].checkList.length == 0 && this.list[i].checkList == "") {
        this.$message.info("未做题目：" + this.list[i].QuestionTitle);
        return false;
      }
      this.number++;
    },
    handChange(b) {
      console.log(b, "");
      let arr = {};
      arr.questionId = b.QuestionId;
      arr.questionCategory = b.QuestionCategory;
      arr.answer = b.checkList.toString();
      let stauts = 0;
      this.obj.forEach((item) => {
        if (item.questionId == arr.questionId) {
          item.answer = arr.answer;
          stauts = 1;
        }
      });
      if (stauts == 0) {
        this.obj.push(arr);
      }
    },
    quitPractice() {
      this.$router.go(-1);
    },
    init() {
      this.answer = this.$route.query;
      this.getquestionBankQueryChapterQuestion();
    },
    //立即交卷
    async buy() {
      let parm = {
        ChapterId: this.answer.Id,
        courseId: this.answer.CourseId,
        studentId: this.user.Id,
        Category: 1, //0试卷，1章节
        questionItems: this.obj,
        QuestionCategory:this.answer.questionCategory
      };
      let state = 0;
      if (this.obj == 0) {
        this.$message.info("先答题再提交。");
        return false;
      }
      // this.list.some((item) => {
      //   if (item.checkList.length == 0 && item.checkList == "") {
      //     this.$message.info("未做题目：" + item.QuestionTitle);
      //     state = 1;
      //     return true;
      //   }
      // });
      if (state != 1) {
        this.useTime = common.timeCounting(false);
        const res = await questionBankSavePaperRecord(parm);
        if (res.success === true) {
          this.$message.success(res.msg);
          let obj = {
            type:2,//1套卷练习2章节练习
            useTime:this.useTime
          }
          //this.start (false);
          setTimeout(() => {
            this.$router.push({
              path: "/onlineQuestionBank/examinationResults",
              query: {...res.response,...obj},
            });
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      }
    },
    //章节练习列表
    async getquestionBankQueryChapterQuestion() {
      let parm = "?chapterId="+this.answer.Id+"&studentId="+this.answer.UserID+"&questionCategory="+this.answer.questionCategory;
      const res = await questionBankQueryChapterQuestion(parm);
      if (res.success === true) {
        this.list = res.response;
        this.list.Name = this.answer.Name;
        this.list.forEach((item) => {
          if (
            item.QuestionCategory === 0 ||
            item.QuestionCategory === 1 ||
            item.QuestionCategory === 2
          ) {
            this.$set(item, "checkList", []);
          } else {
            this.$set(item, "checkList", "");
          }
          this.$set(item, "parsingShow", false);
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    start (bolean) {
        let _this = this
        let hour, minute, second
        hour = minute = second = 0
        if (bolean === true) {
          _this.timer = setInterval(function () {
            if (second >= 0) {
              second = second + 1
            }
            if (second >= 60) {
              second = 0
              minute = minute + 1
            }
            if (minute >= 60) {
              minute = 0
              hour = hour + 1
            }
            _this.useTime = hour + '时' + minute + '分' + second + '秒'
            console.log(_this.callinTime);
          }, 1000)
        } else {
          window.clearInterval(_this.timer)
        }
      },
  },
  destroyed(){common.timeCounting(false)},
};
</script>

<style lang="less" scoped>
.answer {
  // margin-top: -30px;
  background-color: #f5f5f5;
  font-size: 14px;
  .cont {
    .top {
      background-color: #fff;
      margin-bottom: 10px;
      .title {
        line-height: 100%;
        padding: 20px 0;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        i {
          font-weight: bold;
        }
      }
    }
    .list {
      min-height: 350px;
      margin-bottom: 15px;
      background-color: #fff;
      .li {
        .paperBank {
          margin-bottom: 5px;
          padding: 20px 30px;
          background-color: #fff;
          .questionTitle {
            margin-bottom: 30px;
            display: flex;
            justify-content: space-between;
            .correction {
              margin-left:5px;
              color: #c5c5c5;
              font-size: 12px;
              width: 40px;
              cursor: pointer;
            }
            p {
              line-height: 22px;
              // height: 22px;
            }
            .tmtype {
              flex:1;
              span {
                &:first-child {
                  display: inline-block;
                  background-color: #3A78F9;
                  line-height: 22px;
                  height: 22px;
                  color: #fff;
                  padding: 0 8px;
                  border-radius: 20px;
                  margin-right: 10px;
                  font-size: 12px;
                }
              }
            }
            .tmmc {
              margin-top: 10px;
            }
          }
          .el-radio-group,
          .el-checkbox-group {
            .el-checkbox {
              // display: flex;
              // align-items: center;
            }
            .el-radio,
            .el-radio__input,
            .el-checkbox,
            .el-checkbox__input {
              white-space: normal !important;
              line-height: 175%;
            }
            p {
              margin-bottom: 5px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          .el-textarea {
            margin-bottom: 10px;
          }
          .js{
            line-height: 175%;
            font-size: 12px;
          }
        }
      }
    }
    ul {
      padding: 20px 30px;
      background-color: #fff;
      overflow: hidden;
      li {
        min-width: 30px;
        height: 30px;
        line-height: 30px;
        float: left;
        cursor: pointer;
        border: 1px solid #bbb;
        text-align: center;
        margin: 0 10px 10px 0;
        display: inline-block;
      }
      .bgc_active {
        background-color: #1288f4;
        border: 1px solid #1288f4;
        color: #fff;
      }
      .bgc_active1 {
        background-color: #cccccc;
        border: 1px solid #cccccc;
        color: #fff;
      }
    }
    .xs{
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-bottom: 20px;
      div{
        i{
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 1px solid #bbb;
          margin-right: 5px;
        }
        &:nth-child(1){
          i{
            background-color: #1288f4;
            border: 1px solid #1288f4;
          }
        }
        &:nth-child(2){}
        &:nth-child(3){
          i{
            background-color: #cccccc;
            border: 1px solid #cccccc;
          }}
      }
    }
    .qhtm {
      background-color: #fff;
      .el-row {
        text-align: center;
        padding: 10px 0;
        font-size: 16px;
        margin-bottom: 20px;
        .el-col {
          cursor: pointer;
          img {
            width: 20px;
            vertical-align: text-bottom;
          }
        }
      }
    }
  }
}
</style>